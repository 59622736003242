import React, { useEffect } from "react"
import { graphql } from "gatsby"

import "aos/dist/aos.css"
//import { parse } from "tldts"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import Section from "../components/Section"
import SectionTitle from "../components/SectionTitle"
import BodyText from "../components/BodyText"
import Columns from "../components/Columns"
import Column from "../components/Column"
import FeaturedProject from "../components/FeaturedProject"
import IndieLink from "../components/IndieLink"

const HomePageTemplate = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  const {
    seo,
    pageSettings: { showFooter },
    headerSettings: { headerImages, title, subTitle },
    homepageSettings: {
      intro: { title: introTitle, content: introContent },
      featuredProject: { title: projectTitle },
      whatWeDo: { title: whatWeDoTitle, content: whatWeDoContent },
    },
  } = page

  return (
    <Layout showMenu={false} showFooter={showFooter} page={page}>
      <Seo seo={seo} />
      <MountainHeader headerImages={headerImages} />
      <PageWrapper additionalClassName="home">
        <PageTitleSection
          title={title}
          subtitle={subTitle}
          buttons={page.homepageSettings.contentButtons}
          logos={page.homepageSettings.contentLogos}
          links={false}
          isHomePage={true}
        />

        <Section
          dataAos="fade-up"
          additionalClasses="double-top-padding has-background-black-bis"
        >
          <SectionTitle size="is-size-2 is-size-2-mobile">
            {introTitle}
          </SectionTitle>
          <Columns mobile={true}>
            <Column size={{ all: 9 }}>
              <BodyText
                text={introContent}
                weight="bold"
                padding={true}
                size="is-size-3 is-size-3-mobile"
              />
              <div className="content-padding">
                <IndieLink text="ABOUT US" marginSize="medium" url="about" />
              </div>
            </Column>
          </Columns>
        </Section>

        <Section dataAos="fade-up" additionalClasses="double-top-padding">
          <FeaturedProject sectionTitle={projectTitle} />
        </Section>

        <Section dataAos="fade-up">
          <SectionTitle>{whatWeDoTitle}</SectionTitle>
          <Columns mobile={false}>
            <Column size={{ all: 6 }}>
              <BodyText
                text={whatWeDoContent}
                weight="normal"
                padding={true}
                size="is-size-5  is-size-5-mobile"
              />
              <div className="content-padding">
                <IndieLink text="Services" marginSize="large" url="about" />
              </div>
            </Column>
          </Columns>
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default HomePageTemplate

export const pageQuery = graphql`
  query HomePageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      # use common page fields from fragment @ src/fragments.js
      ...pageFields
      homepageSettings {
        featuredProject {
          title
        }
        intro {
          title
          content
          fieldGroupName
          link {
            ... on WpPage {
              id
              uri
            }
          }
        }
        whatWeDo {
          title
          content
          fieldGroupName
          link {
            ... on WpPage {
              id
              uri
            }
          }
        }
        contentButtons {
          contentButtonsCta {
            url
            title
          }
        }
        contentLogos {
          contentLogosImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
